import { Account } from './Account';
import { Model } from './Model';
import { Status } from './Status';

export interface UserProfile extends Model {
  email?: string;
  password?: string;
  role?: number;
  subRole?: number;
  tier?: string;
  ownedAccounts?: Account[];
  subAccountID?: number;
}

export const NewUser = (): UserProfile => {
  const userProfile: UserProfile = {
    ID: 0,
    userID: 0,
    accountID: 0,
    email: 'New Email',
    password: '',
    createdAt: new Date(),
    status: Status.Active,
    updatedAt: new Date(),
    DeletedAt: null,
    name: ''
  };

  return userProfile;
};

export enum UserRole {
  root = 1,
  admin = 2,
  publisher = 3,
  advertiser = 4,
  sales = 5
}
