import axios from 'axios';

var apiBaseURL = process.env.REACT_APP_API_BASE_URL
console.log('apiBaseURL: ', apiBaseURL)
const axiosServices = axios.create((apiBaseURL != undefined) ? {baseURL: apiBaseURL} : {});
 
// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/';
    }

    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;
